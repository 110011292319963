.spread-cards {
  width: 100%;
}

.spread-card__header {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.spread-card__header svg {
  min-width: 10px;
  min-height: 10px;
}

.spread-card p {
  font-size: 1rem;
  margin: 0 !important;
  padding: 0 !important;
}

.spread-card__header-p-bold {
  color: #302f2a;
  font-weight: bold !important;
}

.spread-card__header-p-light {
  color: #9ca3af !important;
  font-weight: 500;
}

.spread-card__header-p-body,
.spread-card__header-view-more {
  font-size: 0.85rem !important;
  color: #111827 !important;
  font-weight: 500;
}

.spread-card {
  border-radius: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: #fff;
  backdrop-filter: blur(0px);
  display: flex;
  width: 100%;
  padding: 16px 20px;
  align-items: flex-start;
  gap: 17px;
  flex-direction: column;
  gap: 2px;
  position: absolute;
  top: 0;
  left: 0;

  animation: spreadPrimary 10s ease-in-out infinite alternate;
  transform: translate(0%, 0%);
  transition: transform 1s ease;
}

.card-back {
  display: flex;

  height: 116px;
  padding: 16px 20px;

  width: 100%;
  border-radius: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.87);
  backdrop-filter: blur(0px);

  position: absolute;

  top: 0;
  left: 0;
  transform: translate(0%, 0%);
  transition: transform 1s ease;
}

.card-back-1 {
  opacity: 0.7;

  animation: spreadOne 10s ease-in-out infinite alternate;
}

.card-back-2 {
  opacity: 0.55;

  transition: transform 0.85 ease;
  animation: spreadTwo 10s ease-in-out infinite alternate;
}

.card-back-3 {
  opacity: 0.4;

  animation: spreadThree 10s ease-in-out infinite alternate;

  transition: transform 0.85 ease;
}

.card-back-4 {
  opacity: 0.25;

  animation: spreadFour 10s ease-in-out infinite alternate;
  transition: transform 0.85 ease;
}

@keyframes spreadPrimary {
  0% {
    transform: translate(0%, 0%);
  }

  75% {
    transform: translate(11%, 60%);
  }

  100% {
    transform: translate(0%, 0%);
  }
}

@keyframes spreadOne {
  0% {
    transform: translate(0%, 0%);
  }

  75% {
    transform: translate(8%, 45%);
  }

  100% {
    transform: translate(0%, 0%);
  }
}

@keyframes spreadTwo {
  0% {
    transform: translate(0%, 0%);
  }

  75% {
    transform: translate(4%, 25%);
  }

  100% {
    transform: translate(0%, 0%);
  }
}

@keyframes spreadThree {
  0% {
    transform: translate(0%, 0%);
  }

  75% {
    transform: translate(0%, 10%);
  }

  100% {
    transform: translate(0%, 0%);
  }
}

@keyframes spreadFour {
  0% {
    transform: translate(0%, 0%);
  }

  75% {
    transform: translate(-3%, -4%);
  }

  100% {
    transform: translate(0%, 0%);
  }
}

@media screen and (max-width: 1190px) {
  .card-back-4,
  .card-back-3 {
    display: none;
  }

  @keyframes spreadPrimary {
    0% {
      transform: translate(0%, 0%);
    }

    75% {
      transform: translate(4%, 29%);
    }

    100% {
      transform: translate(0%, 0%);
    }
  }

  @keyframes spreadOne {
    0% {
      transform: translate(0%, 0%);
    }

    75% {
      transform: translate(-1%, 15%);
    }

    100% {
      transform: translate(0%, 0%);
    }
  }

  @keyframes spreadTwo {
    0% {
      transform: translate(0%, 0%);
    }

    75% {
      transform: translate(-5%, 0%);
    }

    100% {
      transform: translate(0%, 0%);
    }
  }
}

@media screen and (max-width: 1095px) {
  .card-back-4,
  .card-back-3,
  .card-back-2 {
    display: none;
  }

  @keyframes spreadPrimary {
    0% {
      transform: translate(0%, 0%);
    }

    75% {
      transform: translate(-1%, 15%);
    }

    100% {
      transform: translate(0%, 0%);
    }
  }

  @keyframes spreadOne {
    0% {
      transform: translate(0%, 0%);
    }

    75% {
      transform: translate(-5%, 0%);
    }

    100% {
      transform: translate(0%, 0%);
    }
  }
}

@media screen and (max-width: 1078px) {
  .card-back-4,
  .card-back-3,
  .card-back-2,
  .card-back-1 {
    display: none;
  }

  .spread-card {
    position: relative;
    animation: none;
    width: 90%;
  }

  .spread-card .spread-card__header {
    width: 70%;
  }

  .spread-card__header p {
    font-size: 1rem !important;
  }

  .spread-card__header-p-body,
  .spread-card__header-view-more {
    font-size: 1.1rem !important;
  }
}

@media screen and (max-width: 717px) {
  .spread-card {
    width: 100%;
  }
}

@media screen and (max-width: 530px) {
  .spread-card__header-p-bold {
    font-weight: bolder;
    color: #000000;
    font-size: 10.5px;
  }

  .spread-card__header-p-body,
  .spread-card__header-view-more {
    font-size: 0.85rem !important;
  }
}

@media screen and (max-width: 473px) {
  .spread-card .spread-card__header {
    width: 100%;
  }
}
