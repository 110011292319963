/* For Navigation Bar */
html {
  scroll-behavior: smooth;
  transition: all 500ms ease;
  /* background-color: black !important; */
}

body {
  margin: 0;
}

* {
}

/* For the Overview */
.title {
  display: block;
  font-size: clamp(30px, 4.2em, 5vw);
  font-weight: bold;
  color: white;
  margin-bottom: 0px !important;
  color: var(--Gray-950);
  margin-top: 0.6rem;
}

.slogacontainer {
  border-radius: 10px;
  border: 1px solid var(--Utility-Colors-utility-gray-300);
  display: flex;
  padding: 6px 10px 6px 4px;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  max-width: 410px;
  width: 100%;
}

.title_hero_text {
  display: inline-block;
  font-size: clamp(2.3rem, 4.2em, 5vw);
  font-weight: bold;
  color: white;
  line-height: 1.15;
  margin-bottom: 0px !important;
  color: var(--Gray-950);
  margin-top: 0.6rem;
  padding: 0;
  margin: 0;
}

.slogan1 {
  display: flex;
  align-items: center;
  padding: 0.3rem;
  gap: 0.35rem;
  color: var(--Gray-700);
  font-size: 19px;
  line-height: 1.6;
  --animate-delay: 3.9s !important;
  border-radius: 6px;
  border: 1px solid var(--Gray-200);
  background: var(--Base-White, #fff);
}

.slogan2 {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--Gray-700) !important;
}

.slogan2 .sloganText {
  font-weight: bold;
}

.sloganText {
  margin-bottom: -3px;
}

.slogan1.top {
  font-weight: 700 !important;
}

.sloganDescrip {
  color: var(--Gray-700);

  /* Text xl/Regular */
  font-family: Inter;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */

  max-width: 750px !important;
  width: 100%;
  margin-top: 1.15rem;
}

.sloganBtn {
  border-radius: 6px;
  display: flex;
  max-width: 256px;
  width: 100%;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  /* shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  color: var(--Gray-50);

  /* Text md/Medium */
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.hero_home {
  position: relative;
  /* margin-top: 85px !important; */
  margin-bottom: 60px;
  background: url(../../../public/assets/hero-map-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  /* box-shadow: -1px 15px 10px -12px rgb(32, 32, 32); */
  width: 100%;
  height: 100%;

  margin: 0 auto;
}

.hero_home_content {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;
  flex-wrap: wrap;
}

.heroLeftSide {
  width: 650px;
}

.heroRightSide {
  max-width: 650px;
  position: absolute;
  right: 0;
}

.heroLeftSide img {
  width: 100%;
  object-fit: cover;
  image-rendering: crisp-edges;
}

.hero_home::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(2, 2, 7, 0.5); */
}

.inner {
  vertical-align: middle;
}

.second_segment h1 {
  font-size: clamp(1.6rem, 2.5vw, 3vw);

  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  font-weight: 700;
  color: var(--Gray-950) !important ;
}

.second_segment h1 {
  color: #9ca3af;
}

.hummingBirdText {
  display: flex;
  position: relative;
}

.hummingBirdText img {
  position: absolute;
  /* margin-top: -1.2rem; */
  transform: rotate(15deg);
  /* margin-right: 1.5rem; */
  top: -2.4rem;
  left: -2rem;
}

.arrow_down_vid {
  position: absolute;
  margin-top: 26rem;
}

.second_segment_top p {
  color: var(--Gray-950);
  text-align: center;

  /* Text xl/Regular */
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}

.second_segment_btn {
  max-width: 220px;
  width: 100%;
  margin: 0 auto;
}

.second_segment_top {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.45rem;
}

.second_segment_top p {
  font-size: 19px;
}

.second_segment_card {
  /* min-height: 25em; */
  position: relative;
  border-radius: 15px;
  border: none !important;
  transition: 0.3s ease-out;
  cursor: pointer;
}
.second_segment_card::before {
  content: '';
  position: absolute;
  top: 0;
  /* left: 0; */
  right: 0;
  /* bottom: 0; */
  width: 0%;
  height: 100%;
  border-radius: 15px;
  background: #dbeafe;
  transition: 0.3s ease-out;
}
.second_segment_card:hover::before {
  left: 0;
  width: 100%;
}

.second_segment_card h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 1em;
}

.second_segment_card h2 div {
  color: #9ca3af;
}
.second_segment_card p {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.second_segment_next {
  width: 40em;
  max-width: 100%;
}

.transfers_text_wrap {
  width: 25em;
  max-width: 100%;
}

.transfers_text_wrap h3 {
  font-size: 2em;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.14399999380111694px;
  text-align: left;
}
.transfers_text_wrap p {
  color: #333333;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.image_segment {
  position: relative;
  /* margin-top: 85px !important; */
  background: url(../../../public/assets/intersect_img.jpeg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: -1px 15px 10px -12px rgb(32, 32, 32);
  width: 100%;
  min-height: 100vh;
  /* padding: 0 100px; */
  margin: 0 auto;
}

.image_segment_img {
  display: none;
}

.third_segment h1 {
  /* font-size: 60px; */
  font-size: clamp(35px, 4em, 5.3vw);

  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
}

.third_segment h1 div {
  color: #9ca3af;
}

.third_segment_top {
  width: 62em;
  max-width: 100%;
  margin: auto;
}

.valueables_secion {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.valueable_secion,
.valueable_secion_reverse {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.valueable_secion div:nth-child(2),
.valueable_secion_reverse > div:nth-child(2) {
  max-width: 950px;
  width: 100%;
}

.valueable_secion_reverse > div:nth-child(1) {
  order: 2;
}

.valueable_secion_reverse > div:nth-child(2) {
  order: 1; /* Move the second child to the first position */
}

.valueable_secion_right,
.valueable_secion_reverse .valueable_secion_right {
  display: flex;
  max-width: 480px;
  width: 100%;
  padding: 2.2rem 2.5rem;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  flex: 1 0 0;
  border-radius: 40px;
  background: #e6eaef;
}

.valueable_secion_right_video {
  position: relative;
  max-width: 528px;
  width: 100%;
}

@media screen and (max-width: 1078px) {
  .valueable_secion_right_video {
    max-width: 628px;
  }
}

.valueable_secion_right_header {
  position: relative;
}

.humming_brid_img_valuable {
  position: absolute;
  transform: scaleX(-1) rotate(15deg);
  top: -2.3rem;
  right: -3rem;
}

.valueable_secion_right_video video {
  max-width: 400px;

  width: 100%;
}

.replace_video {
  display: none !important;
}

.valueable_secion_reverse .valueable_secion_right {
  background: #e3cacc;
  color: var(--Gray-950);
}

.valueable_secion_right h2 {
  color: #0d2d3a;
  font-family: Inter;
  font-size: clamp(2.2rem, 1.5vw, 3rem);
  font-style: normal;
  font-weight: 700;
}

.valueable_secion_right p {
  color: var(--Gray-600);
  /* Text xl/Regular */
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;

  line-height: 30px; /* 150% */
}

.valueable_secion_right_graph_p {
  margin-bottom: 4.5rem;
}

.valueable_secion_right_imgCon {
  position: relative;
}

.valueable_secion_right_imgCon_graph {
  max-width: 397px;
  width: 100%;

  height: 200px;
}

.valueable_secion_right_imgCon_shadow {
  position: absolute;
  top: -2.9rem;
  left: 3.2rem;
}

.wait_list_con_img_small_view {
  display: none !important;
}

.wait_list_con {
  margin-top: 4rem;
  border-radius: 24px;
  background: var(--Gray-50, #f9fafb);
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 24px;
  padding: 2rem;
}

.wait_list_con div:nth-child(1) h3 {
  color: var(--Gray-900, #101828);
  /* Display md/Semibold */
  font-family: Inter;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}

.wait_list_con div:nth-child(1) p {
  color: var(--Gray-600, #475467);
  /* Text xl/Regular */
  font-family: Inter;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}

/* buttonn coontainer */
.wait_list_con div:nth-child(1) div {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.wait_list_con div:nth-child(1) div button:nth-child(1) {
  border-radius: 6.777px;
  border: 1.129px solid var(--Gray-300, #d1d5db);
  background: var(--white, #fff);
  box-shadow: 0px 1.129px 2.259px 0px;
  display: flex;
  padding: 10.165px 19.2px;
  justify-content: center;
  align-items: center;
  color: var(--Gray-700, #374151);
  font-family: Inter;
  font-size: 18.071px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.107px; /* 150% */
}

.wait_list_con div:nth-child(1) div button:nth-child(2) {
  border-radius: 6.777px;
  background: var(--Gray-900, #101828);

  display: flex;
  padding: 10.165px 19.2px;
  justify-content: center;
  align-items: center;
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 18.071px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.107px; /* 150% */
}

.second_segment_card.below:hover::before {
  right: 0;
  width: 0%;
}

.one_point_journey_con {
  display: flex;
  justify-content: space-between;
  border-radius: 24px;
  margin-top: 4rem;
  flex-wrap: wrap;
}

.hide-desktop {
  display: none !important;
}

.one_point_journey_item,
.one_point_journey_item_mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  border-radius: 24px 0 0 24px;
  padding: 4rem 2.7rem;
  background: var(--Gray-950);
}

.one_point_journey_item_mobile {
  border-radius: 0px;
  margin-bottom: 4rem;
  margin-top: 1.3rem;
  display: none;
}

.one_point_journey_item h3,
.one_point_journey_item_mobile h3 {
  font-family: Inter;
  color: var(--Base-White, #fff);
  font-size: clamp(2rem, 1.5vw, 2.5vw);
  font-style: normal;
  font-weight: 600;
  line-height: 1.8rem;
  letter-spacing: -0.72px;
}

.one_point_journey_item p,
.one_point_journey_item_mobile p {
  color: var(--Gray-300, #d0d5dd);
  /* Text xl/Regular */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.one_point_journey_item_bth {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
}

.one_point_journey_item_bth button:nth-child(1) {
  border-radius: 6.777px;
  border: 1.129px solid var(--Gray-300, #d1d5db);
  background: var(--white, #fff);
  box-shadow: 0px 1.129px 2.259px 0px;
  display: flex;
  padding: 10.165px 19.2px;
  justify-content: center;
  align-items: center;
  color: var(--Gray-700, #374151);

  font-size: 1.2rem;

  font-weight: 600;
  line-height: 27.107px; /* 150% */
}

.one_point_journey_item_bth button:nth-child(2) {
  border-radius: 6.777px;
  background: var(--Gray-600, #475467);
  display: flex;
  padding: 10.165px 19.2px;
  justify-content: center;
  align-items: center;
  color: var(--white, #fff);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 27.107px; /* 150% */
}

.one_point_journey_right {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  align-self: center;
  margin: 0 auto;
}

.one_point_journey_right div:nth-child(1) {
  height: 150px;
  overflow-y: hidden;
}

.one_point_journey_right div:nth-child(1) video {
  max-width: 200px;
  width: 100%;
  height: 168px;
}

.one_point_journey_right p {
  color: var(--Gray-950, #0c111d);
  font-family: BRFirma-Bold;
  font-size: 40px;
  font-style: normal;
  font-weight: bold;
  line-height: 110%; /* 44px */
}

.keypoint_main_con {
  position: relative;
  min-height: 558px;
}

.keypoint_wave_pattern {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2.5rem;

  z-index: 1;
}

.keypoint_wave_pattern img {
  width: 100%;
  object-fit: cover;
}

.kepoints_con {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: flex-start;
  justify-content: space-between;
}

.kepoints_con_left {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  justify-content: space-between;

  height: 400px;
}

.kepoints_con_left h3,
.kepoints_con_left p {
  max-width: 590px;
  width: 100%;
}

.kepoints_con_left h3 {
  color: var(--Gray-50, #f9fafb);
  /* Display md/Semibold */
  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.8rem; /* 122.222% */
  letter-spacing: -0.72px;
}

.kepoints_con_left p {
  color: var(--Gray-300, #d0d5dd);
  /* Text xl/Regular */
  font-family: Inter;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}

.keypoint_right_content {
  position: relative;
  z-index: 2;
}

.keypoint_show_desktop,
.keypoint_show_mobile {
  max-width: 650px;
  width: 100%;
}

.keypoint_show_desktop img {
  width: 100%;
}

.keypoint_show_mobile {
  display: grid !important;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;
  /* grid-template-columns: repeat(auto-fill, minmax(275px, 1fr)); */
}

.keypoint_show_mobile_card {
  display: flex;

  padding: 16px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 20px;
  border: 2px solid var(--Gray-900, #101828);
  background: var(--Gray-700, #344054);
}

.keypoint_show_mobile_card h3 {
  color: var(--Gray-50, #f9fafb);

  /* Text xl/Semibold */
  font-family: Inter;
  font-size: clamp(1.3rem, 1.4vw, 1.9vw);
  font-style: normal;
  font-weight: 600;
  line-height: 1.8rem; /* 150% */
}

.keypoint_show_mobile_card p {
  color: var(--Gray-300, #d0d5dd);
  /* Text sm/Regular */
  font-family: Inter;
  font-size: clamp(1rem, 1.2vw, 1.5vw);
  font-style: normal;
  font-weight: 400;
  line-height: 1.8rem;
}

.contact_us_con {
  margin: 4rem auto;
  display: flex;
  gap: 3rem;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 4.5rem 2.5rem;
  border-radius: 2rem;
  background: var(--Gray-900, #101828);
}

.contact_us_right_item_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact_us_con_left span {
  color: var(--Gray-50, #f9fafb);

  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
}

.contact_us_con_left h3 {
  color: var(--Gray-25, #fcfcfd);
  /* Display md/Semibold */
  font-family: Inter;
  font-size: clamp(2rem, 1.5vw, 2.5vw);
  font-style: normal;
  font-weight: 600;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}

.contact_us_con_left p {
  /* Text xl/Regular */
  font-family: Inter;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  color: #cdd1d9;
}

.contact_us_right {
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
}

.contact_us_right_item {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: space-between;

  padding: 1.4rem;
  border-radius: 24px;

  max-width: 282px;
  width: 100%;

  background: var(--Gray-700, #344054);
}

.contact_us_right_item div:nth-child(2) h4 {
  color: var(--Gray-25, #fcfcfd);
  /* Text xl/Semibold */
  font-family: Inter;
  font-size: clamp(1.2rem, 1vw, 1.5vw);
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}

.contact_us_right_item_content a {
  /* Text xl/Regular */
  /* Text md/Regular */
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  color: #cdd1d9;
  text-decoration-color: #cdd1d9 !important;
  text-decoration: underline !important;
}

.home_footer {
  display: flex;
  padding: 5rem 2rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 3rem;
  background: var(--Gray-900, #101828);
}

.home_footer div:nth-child(2) ul {
  display: flex;
  gap: 3rem;
}

.home_footer div:nth-child(2) ul li {
  list-style-type: none;
  color: rgba(255, 255, 255, 0.7);
  text-transform: none;
  /* Text md/Semibold */
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.home_footer div:nth-child(3) {
  display: flex;
  align-items: flex-end;
}

.home_footer div:nth-child(3) p {
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: -5px;
}

/* For Footer */
.footer_section ul {
  margin: 0px;
  padding: 0px;
}

.footer_section {
  background: #22225b;
  position: relative;
  padding: 0 30px;
}

.footer_cta {
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}

.single_cta {
  width: fit-content;
  margin: auto;
  text-decoration: none !important;
}

.single_cta i {
  color: #fff;
  font-size: 30px;
  float: left;
  margin-top: 0px;
}

.cta_text {
  padding-left: 15px;
  display: inline-block;
}

.cta_text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta_text span {
  color: #fff;
  font-size: 15px;
}

.footer_content {
  position: relative;
  z-index: 2;
}

.footer_pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer_logo {
  margin-bottom: 30px;
}

.footer_logo img {
  max-width: 200px;
}

.footer_text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}

.footer_social_icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.footer_social_icon a {
  color: #667085;
  font-size: 24px;
}

.footer_social_icon i {
  min-height: 40px;
  min-width: 40px;
  display: block;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  transition: 0.3s ease;
  margin-right: 15px;
}

.footer_social_icon a:hover i {
  position: relative;
  transform: translateY(-5px) !important;
  box-shadow: 0 0 0 1px #fff;
}

.footer_social_icon a:nth-of-type(3):hover {
  color: #3b5998;
}

.footer_social_icon a:nth-of-type(1):hover {
  color: #55acee;
}

.footer_social_icon a:nth-of-type(2):hover {
  color: #eee;
}

.footer_widget_heading h3 {
  color: #667085;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  position: relative;
}

.footer_widget ul li {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  transition: 0.3s ease;
}

.footer_widget ul li:hover {
  transform: translateX(5px);
}

.footer_widget ul li {
  font-size: 14px;
}

.footer_widget ul li a:hover {
  color: #667085;
}

.footer_widget ul li a {
  text-decoration: none;
  color: #fff;
  text-transform: capitalize;
  transition: 0.3s ease;
}

.subscribe_form {
  position: relative;
  overflow: hidden;
}

.subscribe_form input {
  width: 100%;
  padding: 14px 75px 14px 12px;
  background: #fff;
  border: none;
  color: #22225b;
}

.subscribe_form input:focus {
  outline: none;
  border: none;
}

.subscribe_form button {
  position: absolute;
  right: 0;
  background: #1e3a8a;
  padding: 13px 20px;
  border: 1px solid #1e3a8a;
  top: 0;
}

.subscribe_form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright_area {
  background: #22225b;
  padding: 25px 0;
}

.copyright_text p {
  margin: 0;
  font-size: 14px;
  color: #667085;
}

.copyright_text p a {
  color: #ff5e14;
}

.footer_menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer_menu li:hover a {
  color: #fff;
}

.footer_menu li a {
  font-size: 14px;
  color: #667085;
  text-decoration: none;
}

/* Country Select Styles */

.custom_select {
  position: relative;
  width: 150px !important;
  overflow: visible;
}

/* .custom_select::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  background-color: transparent;
  border-radius: 0 8px 8px 0;
  height: 100%;
  transform: translateX(1px);
  cursor: pointer;
} */

/* .custom_select select {

  display: none;
  transition: 0.5s ease;

} */

/* .select_selected {
  background-color: white;

  border-radius: 8px;
  line-height: 1.3;
  height: auto;
  margin-top: 16px;
  font-size: 15px;
  padding: 10px 35px 10px 10px;
  white-space: nowrap;
  word-break: keep-all;
  word-wrap: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
} */

/* .custom_select.change_bg .select_selected {
  background-color: #1d1d1f;
  color: white;
} */

/*style the arrow inside the select element:*/
/* .select_selected:after {
  position: absolute;
  content: '';
  top: 18px;
  right: 10px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #1d1d1f transparent transparent transparent;
} */

/*point the arrow upwards when the select box is open (active):*/
/* .select_selected.select_arrow_active:after {
  border-color: transparent transparent #1d1d1f transparent;
  top: 10px;
}
.custom_select.change_bg .select_selected:after {
  border-color: #fff transparent transparent transparent;
}
.custom_select.change_bg .select_selected.select_arrow_active:after {
  border-color: transparent transparent #fff transparent;
} */

/*style the items (options), including the selected item:*/

.select_items div {
  display: block;
  margin: auto 4px;
  padding: 8px 6px;
}

.selectContainer {
  display: flex;
  padding: '5px 9px';
  cursor: 'pointer';
  border: '1px solid #ccc';
  border-radius: '4px';
  width: '150px';
  background-color: '#fff';
  position: relative;
}

/* .select_items div,
.select_selected {
  color: #000;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
} */

/*style items (options):*/
/*.select_items {
  position: absolute;
  height: auto;
  line-height: 1;
  font-size: 15px;
  background-color: white;
  top: 100%;
  left: auto;
  z-index: 99;
  display: inline-block;
  right: 0 !important;
  width: 200px !important;

 overflow-x: auto;
  padding: 10px 6px;
  border-radius: 5px;
  border: #f1f1f1 1px solid;
} */

/*hide the items when the select box is closed:*/
/* .select_hide {

  visibility: hidden;
  opacity: 0;
  transition: 0.5s ease;
} */

/* .select_items div {

} */

/* .select_items div:hover,
.same_as_selected {

  background-color: #1d1d1f;
  color: #fff !important;
  border-radius: 5px;
} */

/* .custom_select.change_bg .select_items div:hover,
.same_as_selected {

  background-color: rgb(245, 245, 247);
  color: #1d1d1f !important;
} */

/* ============== Animations Measurements ================= */
.anim01 {
  --animate-duration: 1300ms;
  --animate-delay: 0.9s;
}

.anim02 {
  --animate-duration: 1500ms;
  --animate-delay: 0.9s;
}

.anim03 {
  --animate-duration: 1700ms;
  --animate-delay: 0.9s;
}

.anim04 {
  --animate-duration: 1900ms;
  --animate-delay: 0.9s;
}

.anim05 {
  --animate-duration: 2100ms;
  --animate-delay: 0.9s;
}

.anim06 {
  --animate-duration: 2300ms;
  --animate-delay: 0.9s;
}

/* Media Queries */

@media screen and (min-width: 1585px) {
  .keypoint_show_desktop,
  .keypoint_show_mobile {
    max-width: 950px;
  }
}

@media screen and (max-width: 1010px) {
  .valueable_secion,
  .valueable_secion_reverse {
    flex-direction: column;
  }

  /* .valueable_secion div img, */

  .valueable_secion_reverse div:nth-child(1) img,
  .valueable_secion div:nth-child(1) > img:nth-child(1) {
    width: 100%;
    max-height: 620px;
    image-rendering: crisp-edges;
    object-fit: cover;
    object-position: 50% 0%;
    border-radius: 2rem;
  }

  .valueable_secion div:nth-child(2),
  .valueable_secion_reverse div:nth-child(2) {
    max-width: 100%;
  }

  /* Order to norm */
  .valueable_secion_reverse > div:nth-child(1) {
    order: 1;
  }

  .valueable_secion_reverse > div:nth-child(2) {
    order: 2;
  }

  .valueable_secion_reverse > div:nth-child(2) p {
    width: 100%;
    text-align: left;
  }
}

@media screen and (max-width: 730px) {
  .keypoint_show_desktop {
    display: none !important;
  }

  .keypoint_show_mobile {
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  }
}

@media screen and (max-width: 530px) {
  .arrow_down_vid {
    display: none;
  }

  .valueable_secion_right_video {
    border-radius: 12px;
  }

  .replace_video {
    display: flex !important;
    flex-direction: column;

    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: #fff;
    border-radius: 1rem;

    padding: 0.8rem;
    align-items: flex-start;
    gap: 0.8rem;
    min-width: 240px;
  }

  .replace_video .header_video_replace {
    display: flex;
    gap: 1rem;
    align-items: center;
    color: #302f2a;
    flex-wrap: wrap;
  }

  .replace_video .header_video_replace h3 {
    color: #302f2a;
    font-size: 1.2rem;
    margin-bottom: 0;
  }

  .replace_video .header_video_replace span {
    margin-top: 0px;
  }

  .replace_video > p {
    color: var(--Gray-900, var(--Gray-900, #111827));
    font-family: Helvetica;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6rem;
    margin-bottom: 0;
  }

  .valueable_secion_right_imgCon_shadow {
    max-width: 300px;
    top: -2.4rem;
  }

  .hero_home_content {
    padding-top: 0;
  }

  .valueable_secion__img_con {
    display: none;
  }

  .valueable_secion_right,
  .valueable_secion_reverse .valueable_secion_right {
    padding: 2.2rem 1rem;
  }

  .contact_us_con {
    padding: 4.5rem 1rem;
  }

  .contact_us_right_item {
    min-width: 100%;
  }
}

@media screen and (max-width: 470px) {
  .valueable_secion_reverse div:nth-child(1) img,
  .valueable_secion div:nth-child(1) > img:nth-child(1) {
    max-height: 100%;
  }

  .valueable_secion_right_imgCon_shadow {
    max-width: 210px;
    top: 1rem;
  }
}

/* Immediate break */
@media screen and (max-width: 1343px) {
  .kepoints_con_left {
    height: auto;
    width: 100%;
  }

  .keypoint_right_content {
    min-width: 100%;
  }
}

@media screen and (max-width: 1360px) {
  /* .heroRightSide {
    position: relative;
    display: none;
  } */

  .heroRightSide {
    max-width: 450px;
    position: absolute;
    right: 0;
  }
}

@media screen and (max-width: 1118px) {
  .heroRightSide {
    max-width: 370px;
    position: absolute;
    right: 0;
  }

  .sloganDescrip {
    max-width: 450px !important;

    width: 100%;
  }
}

@media screen and (max-width: 865px) {
  .heroRightSide {
    position: relative;
    display: none;
  }

  .sloganDescrip {
    max-width: 650px !important;

    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .heroLeftSide {
    max-width: 950px;
  }

  .valueable_secion_right h2 {
    font-size: clamp(3rem, 1.5vw, 4.2rem);
  }
}

@media screen and (min-width: 1590px) {
  .heroLeftSide {
    position: relative;
  }

  .keypoint_right_content {
    width: 850px;
  }
}

/* Before mykes code below queri exist */

@media (max-width: 480px) {
  .footer-col ul,
  ul li,
  ul li a {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media screen and (max-width: 576px) {
  nav {
    overflow: visible;
  }

  nav ul {
    float: none;
  }

  nav li {
    width: 100%;
    display: block;
    margin: 0;
    height: 40px;
    line-height: 40px;
    padding-bottom: 30px;
  }

  nav li a:hover {
    color: #0056b3;
  }

  ._break {
    display: inline-block !important;
  }
}

@media screen and (max-width: 950px) {
  .image_segment {
    display: none;
  }
  .image_segment_img {
    display: block;
  }
}

@media screen and (max-width: 576px) {
  .transfers_text_wrap h3 {
    font-size: 1.4em;
    font-weight: 700;
    line-height: 1.5;
    text-align: left;
  }
}

@media screen and (max-width: 795px) {
  .one_point_journey_con {
    display: none !important;
  }

  .one_point_journey_item_mobile {
    display: flex;
  }

  .one_point_journey_item_mobile_header {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

@media screen and (max-width: 1085px) {
  .wait_list_con_img_large_view {
    display: none !important;
  }

  .wait_list_con_img {
    max-width: 550px;
    width: 100%;
  }

  .wait_list_con_img_small_view {
    display: block !important;
    width: 100%;
  }
}

@media screen and (max-width: 1029px) {
  .wait_list_con_img {
    max-width: 450px;
  }
}

@media screen and (max-width: 1018px) {
  .wait_list_con_img {
    max-width: 355px;
  }
}

@media screen and (max-width: 822px) {
  .wait_list_con_img {
    max-width: 305px;
  }
}

@media screen and (max-width: 768px) {
  .wait_list_con_img {
    max-width: 455px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 540px) {
  .slogacontainer {
    max-width: 328px !important;
    flex-wrap: nowrap !important;
    gap: 0px !important;
    justify-content: stretch;
  }

  .sloganText {
    font-size: 11px;
  }

  .valueable_secion_right_p {
    line-height: 30px !important;
  }

  .one_point_journey_item,
  .kepoints_con,
  .wait_list_con {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
  }

  .one_point_journey_item h3,
  .kepoints_con_right h3,
  .wait_list_con div h3 {
    font-size: 34px;
    line-height: 44px;
  }

  .keypoint_show_mobile_card h3 {
    font-size: 20px !important;
  }

  .one_point_journey_item_bth button,
  .wait_list_con_btns button {
    max-width: 172px;
    font-size: 17px !important;
  }

  .contact_us_con_left span {
    font-size: 1.1rem !important;
    font-weight: 600;
    line-height: 24px;
  }

  .home_footer {
    justify-content: center;
  }

  .home_footer_copy_right p {
    text-align: center;
  }
}
