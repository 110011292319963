.home-features {
  margin-top: 10rem;
}

.home-feature {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  width: 100%;
  gap: 2.5rem;
  align-items: center;
  border-radius: 32px;
  background: var(--Gray-950);
  padding: 4rem 2.7rem;
}

.home-feature-right_animate-content img {
  max-width: 250px;
}

.active-card-left {
  animation: scaleOut 0.85s ease-in-out forwards;
  opacity: 0;
}

/* Animation */

@keyframes scaleOut {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.card-2-items {
  display: flex;
  padding: 0 2rem;
  flex-direction: column;
  align-items: center;
  border-radius: 12.233px;
  gap: 1rem;
  max-width: 200px;
  width: 100%;
  background: #fff;
  padding-top: 1rem;
}

.card-2-items p {
  color: var(--Base-Black, #000);
}

.card-2-items svg {
  width: 45px;
}

.home-feature-header h2 {
  color: var(--Gray-25);

  /* Display lg/Bold */
  font-family: Inter;
  font-size: clamp(1.5rem, 1.2vw, 1.7vw);
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: -0.96px;
}

.home-feature-header p {
  color: var(--Gray-300);

  /* Text xl/Regular */
  font-family: Inter;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8rem;
}

.home-feature-tags {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.home-feature-tag,
.active-card-tag {
  display: flex;
  max-width: 88px;
  width: 100%;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 8px;
  border: 2px solid var(--Gray-900);
  background: var(--Base-White, #fff);
  position: relative;
}

.active-card-tag {
  background: var(--Gray-900, #101828);
}

.active-card-tag span {
  position: relative;
  z-index: 2;
  animation: text-color-change 2s ease-in forwards;
}

@keyframes text-color-change {
  0% {
    color: white;
  }
  100% {
    color: black;
  }
}

.active-card-tag::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-radius: 6px;
  background-color: white;
  opacity: 0.45;
  z-index: 1;
  animation: slide-in 2s forwards 1s;
  transition: opacity 1s ease;
}

@keyframes slide-in {
  0% {
    width: 0;
    opacity: 0.45;
  }

  50% {
    width: 100%;
    /* opacity: 0.66; */
  }

  100% {
    width: 100%;
    opacity: 1;
  }
}

.home-feature-right {
  position: relative;
}

.home-feature-right img {
  border-radius: 1.5rem;
  color: #fff;
}

.home-feature-right_animate-content .card-3-items {
  display: flex;

  padding: 8px;
  align-items: center;
  gap: 8px;

  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.55);
  background: rgba(255, 255, 255, 0.56);
  backdrop-filter: blur(5.400000095367432px);
  min-width: 100px;
}

.home-feature-right_animate-content .card-3-items span {
  font-weight: 600;
  color: #000000;
}

.home-feature-right_animate-content .card-3-items span {
  font-size: clamp(0.85rem, 1.2vw, 1.4rem);
}

.home-feature-right_animate-content img,
.home-feature-right_animate-content .card-3-items,
.home-feature-right_animate-content .img-scale-2 {
  position: absolute;
  z-index: 2;
}

.right-content-3 {
  visibility: hidden;
}

.right-content-3 img {
  width: 100%;
}

.right_animate-content-3 {
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s ease-in-out;
  animation: makeVisible 2s ease-in-out forwards;
}

@keyframes makeVisible {
  from {
    visibility: visible;
    opacity: 0;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

.right_animate-content-3__img1 {
  top: 50%;
  left: 0;
  transform: translate(-35%, 0%);
}

.right_animate-content-3__img2 {
  top: 50%;
  left: 50%;
  transform: translate(58%, 200%);
}

.right_animate-content-3__img3 {
  top: 0;
  left: 50%;
  transform: translate(95%, 45%);
}

.img-flip {
  top: 70%;
  left: 10%;
  transform: translate(0%, 100%);
}

.right-content-1 {
  visibility: hidden;
}

.right-content-1 .img-flip {
  transform: scaleY(-1);
}

.right_animate-content-1 .img-flip {
  visibility: hidden;
  transform: scaleY(-1);

  transition: visibility 0.7s ease;
  animation: flipUp 0.85s ease-in-out forwards;
}

@keyframes flipUp {
  from {
    visibility: hidden;
    transform: scaleY(-1);
  }
  to {
    visibility: visible;
    transform: scaleY(1);
  }
}

/* Imag positioning */
.img-scale {
  top: 8%;
  left: 55%;
  transform: translate(0%, 0%);
}

.right-content-1 .img-scale {
  opacity: 0;
  transform: scale(0);
}

.right_animate-content-main-img__con .main-img::before,
.right_animate-content-2__img-con .main-img::before {
  content: '';
  background: rgba(10, 10, 10, 0.2);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  height: 0;
  border-radius: 8px;
  z-index: 1;
  transition: opacity 1s ease;
  animation: slideUp 0.85s ease-in-out forwards 0.5s;
}

@keyframes slideUp {
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 100%;
  }
}

.right_animate-content-1 .img-scale {
  opacity: 0;
  transform: scale(0);
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  animation: scaleUp 0.35s ease-in-out forwards 0.65s;
}

@keyframes scaleUp {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.right-content-2 {
  opacity: 0;
}

.right_animate-content-2 {
  opacity: 0;
  transform: scale(0);
  animation: scaleUp 0.85s ease-in-out forwards 0.5s;
}

.img-scale-2 {
  top: 0;
  left: 50%;
  transform: translate(-70%, 63%);
}

/* Media Queries */

@media screen and (max-width: 1065px) {
  .img-scale-2 {
    transform: translate(-45%, 63%);
  }
}

@media screen and (max-width: 920px) {
  .home-features {
    height: calc(670px + 100px);
  }

  .home-feature {
    grid-template-columns: 1fr;
    column-gap: 3rem;
  }

  .home-feature-right {
    max-width: 420px;
    width: 100%;
    float: left;
    justify-self: center;
  }

  .img-scale-2 {
    transform: translate(-50%, 63%);
  }
}

@media screen and (max-width: 530px) {
  .right_animate-content-3__img1 {
    transform: translate(-10%, 0%);
  }
  .right_animate-content-3__img2 {
    transform: translate(10%, 200%);
  }

  .img-scale-2 {
    /* width: 200px; */
    transform: translate(-50%, 63%);
  }

  .home-feature-tags {
    gap: 0.6rem;
  }

  .home-feature {
    padding: 4rem 1.2rem;
  }

  .home-feature-header h2 {
    font-size: 2.1rem;
    line-height: 39.79px;
  }
}

@media screen and (max-width: 445px) {
  .right_animate-content-3__img1 {
    transform: translate(-10%, 0%);
    width: 90px;
  }

  .right_animate-content-3__img3 {
    transform: translate(65%, 45%);
    width: 90px;
  }

  .right_animate-content-3__img2 {
    width: 140px;
    transform: translate(7%, 220%);
  }

  .img-scale-2 {
    /* width: 165px; */
    transform: translate(-45%, 53%);
  }

  .img-flip {
    width: 195px;
    left: 10%;
  }

  .img-scale {
    width: 145px;

    left: 50%;
  }
}

@media screen and (max-width: 370px) {
  .right_animate-content-3__img1 {
    transform: translate(-10%, 0%);
    width: 80px;
  }

  .right_animate-content-3__img3 {
    transform: translate(45%, 35%);
    width: 80px;
  }

  .right_animate-content-3__img2 {
    width: 130px;
    transform: translate(5%, 180%);
  }

  .img-scale-2 {
    /* width: 175px; */
    transform: translate(-45%, 53%);
  }
}

@media screen and (max-width: 310px) {
  .right_animate-content-3__img3 {
    transform: translate(30%, 35%);
    width: 80px;
  }

  .right_animate-content-3__img2 {
    width: 130px;
    transform: translate(-5%, 150%);
  }

  .img-scale-2 {
    width: 155px !important;
    transform: translate(-45%, 53%);
  }
}
