.carousel {
  position: relative;
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slides {
  display: flex;
  gap: 1.5rem;
  height: 450px;
  position: relative !important;
  width: 650px;
}

.carousel__slide {
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: scale(0.85);
  transition: transform 0.7s ease-in-out; */

  margin-top: -2.7rem;
}

.carousel__slide img {
  width: 100%;
  height: auto;
  /* transition: transform 0.7s ease-in-out; */
}

.carousel__dots {
  position: absolute;
  bottom: -1px;

  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.carousel__dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ddd;
  margin: 0 5px;

  cursor: pointer;
  transition: background 0.4s ease;
}

.carousel__dot--active {
  background: #333;
}

.carousel__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.carousel__button--prev {
  left: 10px;
}

.carousel__button--next {
  right: 10px;
}

.carousel__slide-con {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 50%;
  transition: transform 0.8s ease;
}

.carousel__slide-con img {
  max-width: 200px;
  width: 100%;
}

.carousel-glass-tag {
  display: flex;

  padding: 8px;
  align-items: center;
  gap: 8px;

  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.55);
  background: rgba(255, 255, 255, 0.56);
  backdrop-filter: blur(5.400000095367432px);
  opacity: 0;
}

.remove-animation-glass {
  opacity: 1;
  transition: opacity 0.5s ease;
  animation: animation-remove-glass-right 1s ease-in-out forwards;
}

@keyframes animation-remove-glass-right {
  from {
    opacity: 0.9;
  }
  to {
    opacity: 0;
  }
}

@keyframes animation-glass-right {
  from {
    opacity: 0.9;
  }
  to {
    opacity: 0.3;
  }
}

.animate-carousel-glass-right {
  opacity: 0;
  transition: opacity 0.5s ease;
  animation: animation-glass-right 1s ease-in-out forwards;
}

@keyframes animation-glass-right {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.carousel-mid-tag {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(40%, -50%) rotate(-30.624deg);
}

.carousel-right-tag {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(60%, 50%);
}
.carousel-right-tag span:nth-child(2) {
  font-size: 1rem;
}

.clip-dark,
.clip-dark-right {
  background: var(--Gray-950, rgba(12, 17, 29, 1));
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.clip-dark svg,
.clip-dark-right svg {
  width: 10px;
  height: 10px;
}

.clip-dark-right svg {
  transform: rotate(-145deg);
}

.carousel-mid-tag span,
.carousel-right-tag span {
  font-weight: bold;
  color: #000;
}

.clip-dark {
  transform: rotate(35deg);
}

/* Animations */
.carousel__slide--mid {
  transform: translate(-50%, 50%) scale(1.2);
  transition: transform 1s ease;
}

.animate-carousel__slide--mid {
  z-index: -1;
  animation: animation-right-mid var(--card-animation-time) ease-in-out forwards;
}

.carousel__slide--left {
  transform: translate(-85%, 50%) scale(0.85);
  transition: transform 1s ease;
  z-index: -2;
}

.animate-carousel__slide--left {
  animation: animation-mid-left var(--card-animation-time) ease-in-out forwards;
}

.carousel__slide--right {
  transform: translate(-15%, 50%) scale(0.85);
  transition: transform 1s ease;
  z-index: -2;
}

.animate-carousel__slide--right {
  z-index: -2;
  animation: animation-mid-right var(--card-animation-time) ease-in-out forwards;
}

/* Card to mid */
@keyframes animation-right-mid {
  from {
    transform: translate(-15%, 50%);
  }
  to {
    transform: translate(-50%, 50%) scale(1.2);
  }
}

/* Card to left */
@keyframes animation-mid-left {
  from {
    transform: translate(-50%, 50%) scale(1.2);
  }
  to {
    transform: translate(-85%, 50%) scale(0.85);
  }
}

/* Card to right */
@keyframes animation-mid-right {
  from {
    transform: translate(-85%, 50%) scale(0.67);
    opacity: 0.5;
  }
  to {
    transform: translate(-15%, 50%) scale(0.85);
    opacity: 1;
  }
}

.hide-desktop-view {
  display: none;
}

@media (max-width: 768px) {
  .carousel {
    width: 100%;
  }

  .carousel__slide {
    min-width: 50%;
  }

  .carousel__button {
    padding: 5px;
  }
}

.hide-mobile-view {
  display: none !important;
}

.hide-desktop-view {
  display: flex;
}

@media screen and (max-width: 1025px) {
  .mobile-glass {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .hide-mobile-view {
    display: flex !important;
  }

  .hide-desktop-view {
    display: none;
  }

  .carousel__slides {
    max-width: 450px;
    height: 550px;
    overflow: hidden;
  }

  .carousel__slides .main-img {
    max-width: 300px;
    width: 100%;
  }

  .carousel-mobile__slide--mid {
    transform: translate(-60%, 0%) scale(1.3);
    transition: transform 1s ease;
  }

  .carousel-mobile__slide--left {
    transform: translate(-40vw, 0%) scale(0.65);
    transition: transform 1s ease;
  }

  .carousel-mobile__slide--right {
    transform: translate(-15%, 0%) scale(0.65);
    transition: transform 1s ease;
  }

  .animate_mobile_mid {
    z-index: -1;

    animation: animation-right-mobile-mid var(--card-animation-time) ease-in-out
      forwards;
  }

  /* from mid to left */
  .animate_mobile_left {
    transform: translate(-50%, 0%) scale(1.3);
    transition: transform 1s ease;
    animation: animation-mid-mobile-left var(--card-animation-time) ease-in-out
      forwards;
  }

  /* Right to mid */
  @keyframes animation-right-mobile-mid {
    from {
      transform: translate(-15%, 0%);
    }

    to {
      transform: translate(-60%, 0%) scale(1.3);
    }
  }

  /* mid to Left */
  @keyframes animation-mid-mobile-left {
    from {
      transform: translate(-60%, 0%) scale(1.3);
    }

    to {
      transform: translate(-45vw, 0%) scale(0.65);
    }
  }

  .glass-mobile-view {
    position: absolute;
    top: 50%;
    transform: translate(5%, -235%);
    opacity: 1;
    z-index: 3;
    max-width: 145px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .glass-mobile-view .span-text {
    font-weight: bolder;
    margin-top: 2px;
  }

  .glass-mobile-view .clip-dark svg {
    transform: rotate(-45deg);
  }

  .glass-mobile-view .clip-dark-right svg {
    transform: rotate(-195deg);
  }

  .carousel__dots {
    bottom: 2.3rem;
    left: 47%;
  }
}

@media screen and (max-width: 350px) {
  .glass-mobile-view {
    width: 100px;
  }
}
